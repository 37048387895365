@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 283 36% 98%;
    --foreground: 283 68% 2%;
    --muted: 283 13% 92%;
    --muted-foreground: 283 4% 37%;
    --popover: 283 36% 98%;
    --popover-foreground: 283 68% 2%;
    --card: 283 36% 97%;
    --card-foreground: 283 68% 1%;
    --banner: 273 49% 82%;
    --border: 277.21 81.13% 31.18%;
    --input: 220 13% 91%;
    --primary: 277.21 81.13% 31.18%;
    --primary-foreground: 283 0% 100%;
    --secondary: 283 7% 90%;
    --secondary-foreground: 283 7% 30%;
    --tertiary: 272 98% 19%;
    --tertiary-foreground: 281 95% 60%;
    --accent: 283 15% 81%;
    --accent-foreground: 283 15% 21%;
    --destructive: 4 84% 36%;
    --destructive-foreground: 4 84% 96%;
    --ring: 283 93% 25%;
    --radius: 0.5rem;
  }

  .dark {
    --background: 256 25% 12%;
    --foreground: 210 20% 98%;
    --card: 256 25% 12%;
    --card-foreground: 210 20% 98%;
    --popover: 256 25% 12%;
    --popover-foreground: 210 20% 98%;
    --primary: 210 20% 98%;
    --primary-foreground: 210 20% 98%;
    --secondary: 215 27.9% 16.9%;
    --secondary-foreground: 210 20% 98%;
    --tertiary: 272 98% 19%;
    --tertiary-foreground: 281 95% 60%;
    --muted: 215 27.9% 16.9%;
    --muted-foreground: 217.9 10.6% 64.9%;
    --accent: 215 27.9% 16.9%;
    --accent-foreground: 210 20% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 20% 98%;
    --border: 277.21 81.13% 31.18%;
    --banner: 275 44% 8%;
    --input: 215 27.9% 16.9%;
    --ring: 262.1 83.3% 57.8%;
  }
}

.shadow-button {
  --tw-shadow: 0px 0px 25px #6000b4;
  --tw-shadow-colored: 0px 0px 25px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.dark .text-gradient {
  background: linear-gradient(#340160, #bd37fa);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.light .text-gradient {
  background: linear-gradient(#340160, #bd37fa);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background;
  }
}

@layer utilities {
  .transition-max-height {
    transition-property: max-height;
  }
}

::-webkit-scrollbar {
  display: none;
}

html {
  scroll-behavior: smooth;
}

/* Importer la police de caractères */
@font-face {
  font-family: 'Adlery';
  src: url('/fonts/Adlery.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap; /* Pour améliorer les performances de chargement */
}

@font-face {
  font-family: 'Reey';
  src: url('/fonts/Reey-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
  font-display: swap; /* Pour améliorer les performances de chargement */
}

@font-face {
  font-family: 'Lora-Bold';
  src: url('/fonts/Lora-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap; /* Pour améliorer les performances de chargement */
}
@font-face {
  font-family: 'Lora-Regular';
  src: url('/fonts/Lora-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap; /* Pour améliorer les performances de chargement */
}
@font-face {
  font-family: 'Lora-BoldItalic';
  src: url('/fonts/Lora-BoldItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap; /* Pour améliorer les performances de chargement */
}
